// Here you can add other styles
$themeColor : rgb(214, 138, 67);
$themeText : #D2B384;
.chat-widget .chat-preview:before{
  width: 100%!important;
}
.sry-screen-modal .avatar{
  margin-bottom: 10px;
  width: 80px;
  height: 80px;
}
.sry-screen-modal .img-avatar{
  border-radius: 50em;
  width: 100%;
  height: 100%;
  font-size: 30px;
  line-height: 80px;
}
.img-gradient{
      background-image: linear-gradient(to top, rgba(245, 245, 245, 0.52), #645d91);
}
.back{
  display: none;
}

//testing
.chatBox_Form{
  border: 1px solid transparent;
}
@media (max-width: 430px){
  .chatBox_Form{
    // margin-top: 20px!important;
    // margin-bottom: 10px;
    // border: 2px solid blue;
  }
}

//

//@media quiries
@media (max-width: 430px){
  .chat-mb{
    // border: 5px solid orange;
    .accept_reject .btn, .sry-btn{
      // border: 5px solid green;
      margin: 9px 9px 0!important;
      width: 90%!important;
    }
  }
  .emojiPicker .emoji-mart{
    width: calc(100% - 4.5rem)!important;
    right: 4.4rem!important;
  }
}
@media (max-width: 576px){
  .hidden-xs{display: none;}
  .site-btn,.site-btn:hover,.swal-button,.swal-button:hover{
    background-color: transparent!important;
    color: $themeText!important;
    border: 1px solid $themeText!important;
    padding: 0.5rem 1.8rem !important;
    border-radius: 7px;
    width: 100%;
    font-size: 0.8rem!important;
    font-family: 'Avenirltstd-black';
    text-transform: capitalize;
    border-radius: 0.25rem;
    font-weight: 400;
  }
  .contactRow.active,.contactRow:hover{background: none!important};
  .contacts .border-top{border: none !important;padding: 2px 0 !important}
  .contactRow{
    box-shadow: 0 0 4px #e2e2e2;
    margin: 0 -10px;
    border-radius: 7px;
  }
  .contacts .chat-social{
    width: 100%!important;
    max-width: 100%!important;
    padding: 0!important;
  }
  .contacts .back{
    display: block!important;
    // color: red;
    // color: red!important;
  }
  .copyRight p,.footer p{
    position: relative !important;
    text-align: center !important;
  }
}
@media(min-width: 720px){
  .shortCode-border:nth-child( odd ) .shortcut-row{
    margin-right: 3.5px;
  }
  .shortCode-border:nth-child( even ) .shortcut-row{
    margin-left: 3.5px
  }
}


@media only screen and (min-width: 375px) and (max-width: 812px) {

  // .back{
  //   top: calc(100vh - 96vh);
  //   color: blue;
  // }

}
@media (max-width: 768px){
  .hidden-sm{display: none;}
  .chat-list{
    width: 100%!important;
    max-width: 100% !important;
    flex: 0 0 100% !important;
  }
  .back{
    position: fixed;

    // left: 0;
    // top: calc(100vh - 96vh);
    // top: calc(100% - 90%);
    color: #d8d8d8;
    cursor: pointer;
    font-size: 17px;
    width: 25px;
    z-index: 999;
    // color: red;


  }
  .chats .back{
    display: block!important;
  }
  .chat{
    position: absolute!important;
    right: -100%;
    width: 100%;
    top: 0;
    bottom: 0;
    transition: 0.5s;
    background-color: #fff;
  }
  .chat-mb{
    right: 0!important;
    flex: 0 0 100%;
    width: 100%;
    max-width: 100% !important;
    z-index: 2;
    .chat-preview{
      text-align: center;
      .img{display: none!important}
      .name{.text-truncate{width:calc(100% - 75px)!important;}}
    }
  }
  .app-body{
    .appSider-mb,.sidebar{
      position: absolute!important;
      left:-100%;
      transition: 0.5s;
    }
    .appSider-mb-sh{
      left:0!important;
      transition: 0.5s;
    }
    .sidebar .user{
      position: fixed;
      width: 60px;
      left: 0;
      margin: 0.6rem 0 3rem;
      .dropdown-menu{display: none;}
    }
    .scrollbar-container.sidebar-nav{
      margin-top: calc(40px + 3.6rem);
    }
    .sidebar .user .img-avatar, .sidebar .user .avatar > img {
      width: 36px!important;
      height: 36px!important;
      font-size: 15px;
      line-height: 2.5rem;
    }
  }
  .chats .chat-list,.contactsList,.settings,.shortCuts{padding-top:50px!important}
  .chat{z-index: 9999!important;}
  .chats .chat-preview.active{background: none!important};
  html:not([dir="rtl"]) .sidebar-lg-show.sidebar-fixed.sidebar-minimized .main, html:not([dir="rtl"]) .sidebar-lg-show.sidebar-fixed.sidebar-minimized .app-footer, html:not([dir="rtl"]) .sidebar-show.sidebar-fixed.sidebar-minimized .main, html:not([dir="rtl"]) .sidebar-show.sidebar-fixed.sidebar-minimized .app-footer{margin-left: 0!important;}
  .contactsRight{padding-top: 50px!important}
}

@media only screen
  and (min-device-width: 375px)
  and (max-device-width: 812px)
  and (-webkit-min-device-pixel-ratio: 3)
  and (orientation: landscape) {

}

@media (min-width:576px) and (max-width:991px){
  .contacts .chat-social{
    width: 260px!important;
    max-width: 260px!important;
  }
  .contactsRight .cross{
    display: block!important;
  }
  .site-btn,.site-btn:hover{
    background-color: $themeText!important;
    color: #fff!important;
    border: none!important;
    padding: 0.7rem 1.8rem !important;
    font-size: 1rem!important;
    font-family: 'Avenirltstd-black';
    text-transform: capitalize;
    border-radius: 0.25rem;
    font-weight: 400;
  }
}
@media (min-width:768px) and (max-width:991px){
  html:not([dir="rtl"]) .sidebar-lg-show.sidebar-fixed.sidebar-minimized .main, html:not([dir="rtl"]) .sidebar-lg-show.sidebar-fixed.sidebar-minimized .app-footer, html:not([dir="rtl"]) .sidebar-show.sidebar-fixed.sidebar-minimized .main, html:not([dir="rtl"]) .sidebar-show.sidebar-fixed.sidebar-minimized .app-footer{margin-left: 60px!important;}
  .sidebar .user .img-avatar, .sidebar .user .avatar > img {
    width: 40px!important;
    height: 40px!important;
  }
  .contactsRight{
    box-shadow: -3px 0 7px -6px #909090;
  }
}
@media (max-width: 991px){
  .sidebar .sidebar-nav,.sidebar-minimized .sidebar .sidebar-minimizer,.sidebar-minimized .sidebar .nav,.sidebar-minimized .sidebar .nav-item:hover{
    width: 60px!important;
  }
  .sidebar-minimized.sidebar-fixed .sidebar{
      width:60px !important;
      margin-left: 0!important;
  }
  .sidebar-minimized .sidebar .nav-link .nav-icon,.nav-items-non-link .item i {
      font-size: 22px;
  }
  .sidebar-minimized .sidebar .nav-dropdown-items .nav-icon{font-size: 20px}
  .sidebar-minimized .sidebar .nav-link,.nav-items-non-link .item{
      padding: 1rem !important;
  }
  .sidebar-minimized .sidebar .nav-item{
    width: 60px!important;
  }
  .sidebar-minimized .sidebar .nav-item:last-child{position: relative!important;}
  // .chat{
  //   display: block!important;
  // }
  .chat-widget{
    width: 100%!important;
  }
  .chat-widget .chat-preview,.chat-widget .preview-address,.chat-widget .bot,.chat-list .chat-preview,.chat-social,.settings{padding-left: 0.8rem!important;padding-right: 0.8rem!important}
  .chats .chat-social{
    position: absolute!important;
    right: -100%;
    top:0;
    width: 260px!important;
    max-width: 260px!important;
    bottom: 0;
    transition: 0.5s;
  }
  .contacts .chat-social{
    position: absolute!important;
    right: -100%;
    top:0;
    bottom: 0;
    z-index: 9999;
    transition: 0.5s;
  }
  .chat-social-hd,.contact-chat-social-hd{
    right: 0!important;
  }
  .contactsLeft{
    width: 100%;
    flex: 0 0 100%;
    padding: 0;
    max-width: 100%;
  }

}
@media (min-width: 992px){
  .sidebar .sidebar-nav,.sidebar-minimized .sidebar .sidebar-minimizer,.sidebar-minimized .sidebar .nav,.sidebar-minimized .sidebar .nav-item:hover{
    width: 85px !important;
    padding-bottom: 0!important;
  }
  .sidebar-minimized .sidebar .nav-link .nav-icon,.nav-items-non-link .item i {
      font-size: 26px;
  }
  .sidebar-minimized .sidebar .nav-link,.nav-items-non-link .item {
      padding: 1.5rem 1.1rem !important;
  }
  .sidebar-minimized .sidebar .nav > .nav-dropdown:hover > .nav-dropdown-items{
    left:85px;
  }
  .sidebar .nav-dropdown.open{background: none!important}
  .nav-dropdown.open > .nav-dropdown-items{display: block!important;}.nav-dropdown-items{display: none};
  .chat-social-hd{
    display: none;
  }
  .chat-widget{
    width: 65%;
  }
  .sidebar-minimized .sidebar .nav > .nav-item{
    width: 85px;
    display: inline-flex;
  }
  .sidebar-minimized .sidebar .nav-dropdown-items .nav-icon{font-size: 24px}
  .sidebar-minimized .sidebar .nav-dropdown-items .nav-item .nav-link,.sidebar-minimized .sidebar .nav-dropdown-items .nav-item{width: 85px}
  .site-btn,.site-btn:hover,.swal-button,.swal-button:hover{
    background-color: #c49b5b !important;
    color: #fff!important;
    border: none!important;
    padding: 0.7rem 1.8rem !important;
    font-size: 1rem!important;
    font-family: 'Avenirltstd-black';
    text-transform: capitalize;
  }
}

// .nav-dropdown:hover > .nav-dropdown-items{display: none;}
  .app-body{margin-top: 0!important;}


  .sidebar-minimized.sidebar-fixed .sidebar{
      width:85px;
      margin-left: 0!important;
  }
  .sidebar-minimized.sidebar-fixed .sidebar .dropdown .name{
      display: none !important;
  }
  .sidebar-minimized .sidebar .nav-item:hover > .nav-link,.sidebar-minimized .sidebar .nav > .nav-dropdown:hover{
    background: none !important;
    width:auto !important;
  }

  html:not([dir="rtl"]) .sidebar-lg-show.sidebar-fixed.sidebar-minimized .main, html:not([dir="rtl"]) .sidebar-lg-show.sidebar-fixed.sidebar-minimized .app-footer, html:not([dir="rtl"]) .sidebar-show.sidebar-fixed.sidebar-minimized .main, html:not([dir="rtl"]) .sidebar-show.sidebar-fixed.sidebar-minimized .app-footer{margin-left: 85px;}
// }
.img-avatar{
  border-radius: 6px;
  background-color: rgb(100,93,145);
  color: #fff;
  font-weight: 600;
  text-align: center;
  line-height: 36px;
}
.chats{
  position: relative;
  .row{
    margin:0;
  }
  .chat{
    padding: 0;
    display: inline-flex;
    .chat-widget-lg{
      width:100%!important;
    }
    .chat-widget{
      float: left;
      transition: 0.3s;
      position: relative;
      .chat-widget-preview{
        padding:35px 0 0 0;
        // height: 100vh;
        // max-height: -webkit-fill-available;
        overflow: auto;
        position: relative;
      }
      .chatApp__room{
        // height: 100vh;
        position: relative;
      }
      .chat-preview{
        padding: 0 1.4rem 12px;
        display: flow-root;
        .name{
          cursor: pointer;
          display: inline;
          font-size: 16px;
        }.img{
          cursor: pointer;
        }
      }
      .bot{
        padding: 1.3rem 1.4rem;
        display: flow-root;
      }
      .preview-address{
        padding: 1.3rem 1.4rem;
        display: flow-root;
        .pro-img{
          width: 37%;
          margin-right: 3%;
        }
        .pro-title{
          float: left;
          width:60%;
        }
        .prop-link{
          width: 95px;
          height: 30px;
          display: inline-block;
          border-radius: 4px;
          text-align: center;
          padding: 7px;
          color: #fff;
          margin-top: 20px;
          margin-right: 10px;
        }
      }
      .conversation{
        display: flex;
      }
      .conversation:before{
        border:none!important;
      }
      .conversation .img-avatar{
        width: 27px!important;
        height: 27px!important;
        font-size: 11px!important;
        line-height: 30px!important;
        position: absolute;
        bottom:0;
        left:0;
      }
    }
    .chat-social{
      padding: 80px 1.4rem 0;
      background-color: rgb(250,250,250);
      text-align: center;
      // height: 100vh;
      overflow: auto;
      width:35%;
      border-left: 1px solid rgba(0,0,0,0.1);
      position: relative;
      transition: 0.5s;
    }
  }
  .chat-list{
    height: 100vh;
    overflow: auto;
    padding:35px 0 0 0;
    border-right: 1px solid rgba(0,0,0,0.1);
    background-color: rgb(250,250,250);
  }
  .searchList{
    max-width: 81%;
    margin: auto;
    padding-left: 21px;
    margin-bottom: 1rem;
    position: relative;
    height: calc(1.5em + 0.5rem + 2px);
    border-radius: 6px !important;
    background: rgba(231,231,231,1);
    .input-group-prepend{
      position: absolute;
      top: 9px;
      left: 9px;
      z-index: 1;
      color: #bfbebe;
    }
    input{
      height: 100%;
      border: none;
      font-size: 16px;
      padding: 4px 7px 0!important;
      background: #e7e7e7;
    }
  }

  .chat-preview:before{
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    height: 5px;
    width: calc(100% - 57px - 1rem);
    border-bottom: 1px solid rgba(0,0,0,0.1);
  }

}
.chat-list .chat-preview{
  padding: 1.3rem 1.4rem;
  position: relative;
  .name{
    line-height: 1.1rem;
  .text-truncate{
    width: calc(100% - 110px - 2rem);
    display: inline-block;
    margin: 0;
    font-size: 0.95rem;
  }small{
    font-size: 0.83rem;
  }}
  .avatar{
    width:38px;
    height: 38px;
    .badge{
      position: absolute;
      right: -12px;
      top: -10px;
      font-size: 10px !important;
      height: 20px;
      width: 20px;
      line-height: 18px;
    }
  }
  .img-avatar{
    width: 38px;
    height: 38px;
  }
}
.chat-preview.chat-row:hover,.chat-preview.active,.contactRow.active,.contactRow:hover{
  background-color: rgba(231,231,231,1);
}
.chat-preview.chat-row,.contactRow{
  cursor: pointer;
}
.social-icons{
  color: white;
  padding: 0.3rem 0.5rem;
  border-radius: 7px;
  margin: 12px 0;
}
.sry-screen .fa-times{
  color: rgb(247, 196, 207);
  font-size: 8rem;
  -webkit-text-stroke-width: 8px;
  -webkit-text-stroke-color: rgb(239, 84, 119);
}
.sry-screen-modal{
  width: 100% !important;
  height: 100% !important;
  position: absolute;
  padding-top: 20%;
  z-index: 1;
  top: 0;
  left: 0;
  text-align: center;
  bottom: 0;
  right: 0;
  background: #fff;
  .sry-screen{
    max-width: 220px;
    margin: 30px auto;
    font-family: 'Avenir-Heavy';
  }
}
.hidden{
  display: none!important;
}
.ln{background: #1484C7}.tw{background: #78CBEF}.fb{background: rgb(83, 123, 188)}
.coversations{
  background: #f1f6ff;
  width: calc(75% - 28px - 1rem);
  padding: 12px;
  border-radius: 7px;
  box-shadow: 0px 2px #d6d6d6;
  border-bottom-left-radius: 0;
}
.scl-box {
  max-width: 33.33%;
  display: inline-block;
  width: 66px;
}
.social-img{
  width: 100px;
  height: 100px;
  line-height: 6.5rem;
  margin: auto;
  margin-bottom: 0.8rem;
  font-size: 1.8rem;
}
.emoji-mart-bar,.emoji-mart-search{display: none;}

//--------------Contacts---------------
.contacts{position: relative;}
.contactsLeft{background: #fff;}
.contactsList{
  // background: #fff;
  padding: 35px 15px 0;
  height: 100%;
  overflow-y: auto;
}
.contactsList .border-top:first-child{border:none!important;padding-top: 0px}
.contactsLeft{
  height: 100vh;
  padding: 0;
}
.contacts .chat-social{
  padding-right: 0;
  height: 100vh;
  .contactsRight{
    height: 100%;
    background: #fff;
    padding: 35px 20px 15px;
    text-align: center;
  }
}
.contactRow{
  padding: 15px 0 0;
}
.contactRow .img-avatar{
  border-radius: 50em;
  margin-top: -9px;
  height: 100%;
}
.contactsRight .cross{
  cursor:pointer;
  position: absolute;
  top: 30px;
  right: 30px;
  font-weight: initial;
  font-size: 22px;
  opacity: 0.5;
  display: none;
}
.settings{
  padding: 1.3rem 1.4rem 5px 1.4rem;
}
.settings .form-group, .settings .row{
  margin: 0;
}
.settings .img-avatar{
  width: 140px;
  height: 140px;
  border-radius: 50em;
  // line-height: 3.5rem;
  line-height: 9rem;
  font-size: 3rem;
  border: 1px solid #eeeeee;
}
.settings .profilePic{
  background-color: #c49b5b  !important;
  cursor: pointer;
  color: #fff!important;
  font-size: 12px!important;
  position: absolute;
  padding: 4px 8px;
  border-radius: 20px;
  right: 10px;
  bottom: 5px;
}
.settings .label{
  margin-bottom: 0.8rem;
  label{
    margin: 0;
    font-size: 0.9rem;
    // color: #5c6873;
  }
}
.settings .form-control{
  border: none;
  border-bottom: 1px solid rgba(0,0,0,0.2);
  border-radius: 0 !important;
  font-size: 1rem;
  padding: 0!important;
}
.settings .label.social input{padding-right: 17px!important}
.settings .label.social i{ position: absolute;right: 18px;bottom: 10px;}
// .brokerageTitle{
//   padding:0!important;
//   border-bottom: 1px solid rgba(0, 0, 0, 0.2);
//   border-radius: 0 !important;
//   height: 38px;
//   input{
//     width: auto;
//     height: 37px;
//     border:none!important;
//     float: left;
//     white-space: nowrap;
//     text-overflow: ellipsis;
//     overflow: hidden;
//   }
// }
.footer .col-12{
  margin-top: 15px;
  .copyRight p{
    position: absolute;
    bottom: 0px;
    margin: 0px;
    right: 15px;
  }
}

/* on/off toggle */
.toggle {
    cursor: pointer;
    display: inline-block;
    margin-right: 10px;
}
.toggle input {
    display: none;
}
.switches {
    margin-top: 4em;
}
.toggle i {
    content: '';
    position: relative;
    top:2px;
    display: block;
    width: 46px;
    height: 20px;
    padding:2px;
    background:#e63855;
    border-radius:8px;
    -webkit-border-radius:8px;
    -moz-border-radius:8px;
    -o-border-radius:8px;
}
.toggle i:before {
    content: '';
    position: absolute;
    z-index: 1;
    top:2px;
    right:2px;
    display: block;
    width:15px;
    height:15px;
    opacity: 1;
    border-radius:1em !important;
    transition: right 0.2s;
    -o-transition: right 0.2s;
    -ms-transition: right 0.2s;
    -moz-transition: right 0.2s;
    -webkit-transition: right 0.2s;
}
.toggle input:checked + i:before {
    right: 2px;
}
.toggle i  {
    transition:all 0.3s;
    -o-transition: all 0.3s;
    -ms-transition:all 0.3s;
    -moz-transition: all 0.3s;
    -webkit-transition: all 0.3s;
}
.toggle i:before {
    background-color:#FFF;
    border-radius:3px;
    -webkit-border-radius:3px;
    -moz-border-radius:3px;
    -o-border-radius:3px;
}
.toggle input:checked + i {
    background:#97d55a;
}
.toggle i:before {
    top: 2px;
    right:29px;
}



.msg-info{
  margin: 3px 0 0;
  text-align: right;
  line-height: 1;
  font-size: 12px;
  .time-stamp{
    // margin-right: 2px;
	  color:#777777;
  }
  .ticks{
    margin-left: 5px;
    position: relative;
  }
	.status-read {color:#0773e4}
}
div#wave {
    position: relative;
    height: 22px;
    padding: 0px;
    border-radius: 20px;
    margin-left: auto;
    margin-right: auto;
}
div#wave .dot {
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    margin: 0px;
    margin-right: 5px;
    background: #5a849c;
    // animation: wave 1.3s linear infinite;
    animation: mercuryTypingAnimation 1.5s infinite ease-in-out;
}
@keyframes mercuryTypingAnimation{
0%{
  -webkit-transform:translateY(0px)
}
28%{
  -webkit-transform:translateY(-5px)
}
44%{
  -webkit-transform:translateY(0px)
}
}

div#wave .dot:nth-child(1){
  animation-delay:200ms;
}
div#wave .dot:nth-child(2){
  animation-delay:300ms;
}
div#wave .dot:nth-child(3){
  animation-delay:400ms;
}
//===========================
// Shortcuts css
.shortCuts{
  position: relative;
}
.shortcut-row{
  border: 1px solid #eee;
  border-radius: 7px;
  margin-bottom: 7px;
  padding: 10px;
  box-shadow: -2px 1px #eee;
  cursor: pointer;
  p{
    margin-bottom: 0.3rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  label{
    color: #c49b5b;
  }
  .shortName{
    width: calc(100% - 60px);
  }
  .edit{
    font-size: 13px;
    i{
      margin: 0 15px 0 0;
      cursor: pointer;
      font-size: 18px;
      &:hover{
      color:  #73818f !important
      }
    }
  }
  .form-control{
    margin-bottom: 10px;
    font-size: 14px!important;
    padding: 0.75rem !important;
  }
  .site-btn{
    font-size: 13px !important;
    padding: 0.5rem 1.5rem !important;
    margin-left: 10px;
  }
}
.shortCuts{
  .heading{
    margin: 12px 0 0;
  }
  .site-btn{
    background-color: #c49b5b !important;
    color: #fff !important;
    border: none !important;
    padding: 0.5rem 1.6rem !important;
  }
}
.sidebar-minimized .sidebar .nav-link .badge{
  display: block !important;
    border-radius: 10rem;
    top: 10%;
    right: 20%;
    font-size: 10px;
    line-height: 17px !important;
    width: 20px;
    height: 20px;
}
@media(max-width: 990px){

.sidebar-minimized .sidebar .nav-link .badge{
  display: block !important;
    border-radius: 10rem;
    top: 5%;
    right: 10%;
    font-size: 10px;
    line-height: 17px !important;
    width: 19px;
    height: 19px;
    position: absolute;
}
}


// History List

span.history_time {

  display: block;
  color:#cccccc;
  font-size:0.8em;
}
.history-list{
  margin-top: 10px !important;
}
.history-list div{
  margin-top: 10px;
}
.history-list div {
  width: 100% !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
