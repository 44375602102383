// Styles
// CoreUI Icons Set
@import '~@coreui/icons/css/coreui-icons.css';
// Import Flag Icons Set
@import '~flag-icon-css/css/flag-icon.min.css';
// Import Font Awesome Icons Set
@import '~font-awesome/css/font-awesome.min.css';
// Import Simple Line Icons Set
@import '~simple-line-icons/css/simple-line-icons.css';
// Import Main styles for this application
@import './scss/style.scss';
@charset "UTF-8";
@font-face {
    font-family: 'Avenir-Medium';
    font-style: normal;
    font-weight: normal;
    src: local('Avenir LT Std 65 Medium'), url('fonts/Avenir/avenirltstd-medium.woff') format('woff');
}
@font-face {
    font-family: 'Avenir-Heavy';
    font-style: normal;
    font-weight: normal;
    src: local('Avenir LT Std 85 Heavy'), url('fonts/Avenir/avenirltstd-heavy.woff') format('woff');
}
@font-face {
    font-family: 'Avenir-Light';
    font-style: normal;
    font-weight: normal;
    src: local('Avenir LT Std 35 Light'), url('fonts/Avenir/avenirltstd-light.woff') format('woff');
}
@font-face {
    font-family: 'Avenirltstd-roman';
    src: url('fonts/Avenir/avenirltstd-roman.eot');
    src: local('?'), url('fonts/Avenir/avenirltstd-roman.woff') format('woff'), url('fonts/Avenir/avenirltstd-roman.ttf') format('truetype'), url('fonts/Avenir/avenirltstd-roman.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Avenirltstd-black';
    src: url('fonts/Avenir/avenirltstd-black.eot');
    src: local('?'), url('fonts/Avenir/avenirltstd-black.woff') format('woff'), url('fonts/Avenir/avenirltstd-black.ttf') format('truetype'), url('fonts/Avenir/avenirltstd-black.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'avenirltstd-book';
    // src: url('fonts/Avenir/avenirltstd-book.eot');
    // src: local('?'), url('fonts/Avenir/avenirltstd-book.woff') format('woff'), url('fonts/Avenir/avenirltstd-book.ttf') format('truetype'), url('fonts/Avenir/avenirltstd-book.svg') format('svg');
    src: url('fonts/Avenir/avenirltstd-book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

$themeColor : rgb(214, 138, 67);
$themeText : #D2B384;
$themeMask :-webkit-gradient(linear, center bottom, center top, color-stop(0, rgba(0, 0, 0, 0.57)), color-stop(1, rgba(0, 0, 0, 0.97)));
body{
  background-color: #fff!important;
  font-family: 'Avenir-Medium';
}
html body .app.flex-row.align-items-center, app-dashboard, app-root{
  height: 100%!important;
  min-height: 100%!important;
}
.heading{
  // color: $themeText;
  margin: 12px 0;
  font-weight: bold;
}
.transparent-btn{
  display: inline-block;
  border: 1px solid #eee;
  padding: 5px 15px;
  font-size: 13px;
  margin-bottom: 10px;
  cursor: pointer;
  color: #c49b5b;;
}
.nav-items-non-link{
  padding: 0;
  margin: 0;
}
.nav-items-non-link .item{
  text-align: center;
  margin: 0!important;
  cursor: pointer;
}
.nav-items-non-link .item i.fa-volume-off{
  color: gray;
}
.nav-items-non-link .item i.fa-volume-up{
  color: #fff;
}
.swal-modal{
  font-family: 'Avenir-Medium';
}
.swal-title{
  font-size: 26px !important;
}
.swal-button-container:first-child:nth-last-child(1) {
/* -or- li:only-child { */
  display: block;
  text-align: right;
}
.swal-footer{
  text-align: center;
}
/* two items */
.swal-footer:first-child:nth-last-child(2),
.swal-footer:first-child:nth-last-child(2) ~ li {

}
.swal-button:focus{box-shadow: none}
.swal-footer{
  margin: 0 !important;
}
.site-btn:disabled,.swal-button:disabled{cursor: not-allowed;}
.notification-container{
  left: 0;
  margin: auto;
  max-width: 100%;
}
.notification{
  padding: 15px 31px 15px 15px!important;
}
.notification:before {
    top: 18px!important;
    left:unset!important;
    right: 9px!important;
    width: 16px!important;
    height: 16px!important;
    font-size: 16px!important;
}
.signup{
  font-family: 'Avenirltstd-black';
  font-size: 15px;
  float: right;
  color: $themeText;
  text-transform: uppercase;
  margin-top: 8px;
  &:hover{
    text-decoration: none;
    color: $themeText;
  }
}
.clr{
  color:#c49b5b;
}
.form-control{
  border-radius: 5px !important;
  padding: .75rem 1.5rem!important;
  font-size: 1.2rem;
  color: #55595c;
  font-family: 'Avenir-Light';
}
.form-control[readonly]{
  background-color: transparent !important;
}
label{
  font-family: Avenir-Heavy;
}
.form-control::-webkit-input-placeholder {
    color: rgba(38, 38, 38, 0.7);
}
.form-control::-moz-placeholder{
    color: rgba(38, 38, 38, 0.7);
}
.form-control:-ms-input-placeholder{
    color: rgba(38, 38, 38, 0.7);
}
.form-control::placeholder {
    color: #a5a5a5;/* rgba(38, 38, 38, 0.7); */
}
.form-control:disabled, .form-control[readonly] {
    background-color: #e8e8e8;
}
.form-control:focus {
    border-color: #84a7f5;
    box-shadow: none;
}
img{
  width:100%;
  vertical-align: middle;
}
.fa-rotate-90{
  webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}
[type=checkbox]{
    width: 17px;
    height: 17px;
    border: 1.5px solid #8a8a8a;
    border-radius: 1px;
    margin-top: 2px;
    -webkit-transition: .2s;
    transition: .2s;
}
.colored-link{
  color: #888;
  cursor: pointer;
  text-decoration: underline;
  font-family: 'Avenir-Light';
  font-size: 1em;
}
.app-header .nav-item .nav-link > .img-avatar, .app-header .nav-item .avatar.nav-link > img{
  margin: 0;
}
.errorMsg{
  line-height: 20px;
  color: red;
  font-size: 12px;
}
// Login css
.logo{
  width: 176px;
  cursor: pointer;
}
.signIn-header{
  text-align: left;
  margin-top: 20px;
}
.signIn-body{
  box-shadow: 0px 4px 0px 0px #c8d3e2;
  border: 1px solid #c8d3e294;
  background-color: #fff;
  border-top-right-radius: 19px;
  border-top-left-radius: 19px;
  border-bottom-right-radius: 19px;
  margin: 10px 0 48px 0 !important;
}
.signIn-left{
  margin-right: 5px;
  max-height: 700px;
  font-family: 'Avenir-Heavy';
  border-top-left-radius: 19px;
  -webkit-mask-size: 365px 726px;
  -webkit-mask-image: -webkit-gradient(linear, center bottom,center top, color-stop(0.00, rgba(0, 0, 0, 0.57)), color-stop(1.00, rgba(0, 0, 0, 0.97)));
}
.signIn-left img{
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 71.4%;
  margin-top: 18px;
}
.signIn-right{
  padding-top: 34px;
  padding-bottom: 34px;
}
.signIn-right label{
  margin-left: 0px !important;
}
.sidebar .nav-dropdown-toggle::before {
    position: absolute;
    top: 50%;
    right: 1rem;
    display: block;
    width: 8px;
    height: 8px;
    padding: 0;
    margin-top: -4px;
    content: "";
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='%23fff' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center;
    -webkit-transition: -webkit-transform .3s;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
}
.sidebar .nav-dropdown.open > .nav-dropdown-toggle::before {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
}
.continue {
  background-color: #c59a5c !important;
  color: #fff !important;
  font-family: 'Avenirltstd-black';
  font-size: 18px !important;
  padding: 0.844rem 2.4rem !important;
  box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12) !important;
  width: 100%;
}
label.black-text{
  font-size: 18px;
  font-weight: 900;
  color: #000;
  font-family: 'Avenirltstd-black';
}
.login-HideShow{
  position: absolute;
  top: 30%;
  right: 2%;
  font-size: 12px;
  cursor: pointer;
  color: rgba(38, 38, 38, 0.5);
}
.site-bg{
  background-color: $themeColor;
  color:#fff!important;
}
//Chats
.card{
  border: 1px solid #e6e6e6!important;
}
.chat-ellipsis{
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: calc(100% - 38px - 1rem);
  font-size: 0.89rem;
}
.navbar-brand-full{
  padding: 10px;
}
.app-header .navbar-toggler-icon{height: 28px;}
.sidebar{
  background: rgb(74, 74, 74);
  height: 100%;
  // overflow-y: auto!important;
}
// .sidebar-minimized .sidebar .nav > .nav-dropdown > .nav-dropdown-items{background: rgb(74, 74, 74);}
.sidebar .user{
  // padding: 19%;
  margin: 2rem 0 4rem;
  text-align: center;
  cursor: pointer;
}
.sidebar .user .img-avatar{
  width: 50px;
  height: 50px;
  width: 50px;
  height: 50px;
  border-radius: 50em;
  margin: auto;
  text-align: center;
  font-size: 18px;
  line-height: 3.3rem;
}
.sidebar .dropdown-menu{
  top: -3rem!important;
}
.sidebar .nav-link .nav-icon,.sidebar .nav-link.active .nav-icon {
    color: rgb(128, 128, 128);
    margin: 0;
}
.sidebar .nav-link.active .nav-icon{
  color: #fff!important;
}
.sidebar .nav-link.active,.sidebar .nav-link,.sidebar .nav-link:hover{
  background: none;
  // display: flex;
  text-align: center;
}

.sidebar .nav-dropdown-toggle::before{
  top:49% !important;
  right: 0.4rem;
}
.sidebar-minimized .sidebar > .nav-item:last-child{
  position: absolute;
  bottom: 0;
}
.sidebar .nav-link.active,.sidebar .nav-link:hover{
  // box-shadow: inset 18px 0 6px -11px rgb(239, 79, 81);
   box-shadow: inset 15px 0 0px -10px #c49b5b;
}
.accept_reject{
  text-align: center !important;
  background: #fff;
  box-shadow: 0px -8px 22px rgba(0, 0, 0,0.1);
  padding: 2px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}
.accept_reject .btn,.sry-btn{
  color: white;
  margin: 9px;
  padding: 8px 60px;
  border-radius: 10px;
  font-family: 'Avenir-Heavy';
}
.accept{background: rgb(158, 213, 197)}
.reject{background: rgb(238, 111, 128)}
.gif-sm-loader{
    border: 6px solid #f3f3f3;
    border-radius: 50%;
    border-top: 4px solid #C49F62;
    border-left: 4px solid #c49f62;
    width: 2em;
    height: 2em;
    position: absolute;
    top: 47%;
    left: calc(50% - 1em);
    -webkit-animation: spin 2s linear infinite;
    animation: spin 1s linear infinite;
}
.gif-md-loader{
    border: 5px solid #f3f3f3;
    border-radius: 50%;
    border-top: 5px solid #C49F62;
    border-left: 5px solid #c49f62;
    width: 4em;
    height: 4em;
    position: absolute;
    top: 47%;
    left: calc(50% - 2em);
    -webkit-animation: spin 2s linear infinite;
    animation: spin 1s linear infinite;
}
/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


//chat css
.chatApp__loaderWrapper {
	margin: 80px auto;
	text-align: center;
}
.chatApp__loaderText {
	color: #4870df;
	font-weight: bold;
}
.chatApp__loader,
.chatApp__loader::before,
.chatApp__loader::after {
	border-radius: 1em;
	width: 1em;
	height: 1em;
	animation-fill-mode: both;
	animation: loading 1.8s infinite ease-in-out;
}
.chatApp__loader {
	margin: auto;
	color: #4870df;
	font-size: 12px;
	position: relative;
	animation-delay: -0.16s;
}
.chatApp__loader::before,
.chatApp__loader::after {
	content: '';
	position: absolute;
	top: 0;
}
.chatApp__loader::before {
	left: -1.5em;
	animation-delay: -0.32s;
}
.chatApp__loader::after {
	left: 1.5em;
}
.chatApp__room {
  // display: flex;
  align-content: flex-start;
  justify-content: center;
  // padding: 5rem 20rem;
  animation: fadeChatApp 0.6s ease-in-out;
  animation-fill-mode: forwards;
  height: 100vh !important;
}
.chatApp__conv {
	flex-grow: 1;
	flex-shrink: 1;
	flex-basis: 0;
	float: left;
  width: 100%;
	// margin: 0 2.5rem;
	box-shadow: 0 35px 20px -30px rgba(0,0,0,0.1);
}
.clearfix::after {
	content: "";
	display: block;
	clear: both;
}
.chatApp__convTimeline {
	display: flex;
	// height: 400px;
	padding: 0.3rem 1.4rem;
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
	background-color: #fff;
	flex-direction: column-reverse;
	overflow-y: auto;
}
.chatApp__convMessageItem {
	padding: 1rem 0 0 0;
	opacity: 0;
	transition: all 0.15s ease-in-out;
	animation: fadeNewMessage 0.5s;
	animation-fill-mode: forwards;
}
.chatApp__convMessageAvatar,.chatApp__convMessageAvatar img {
	width: 27px;
	height: 27px;
	border-radius: 6px;
	image-rendering: -webkit-optimize-contrast;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
  background-color: #645d91;
  color: #fff;
  font-weight: 600;
  text-align: center;
  font-size: 10px;
  line-height: 30px;
  position: absolute;
  bottom:0;
  left: 0;
}
.chatApp__convMessageValue {
	position: relative;
	max-width: calc(88% - 37px);
	font-size: 15px;
	line-height: 18px;
}
.chatApp__convMessageValue .message{
  min-height: 40px;
	padding: 12px;
	border-radius: 4px;
  box-shadow: 0px 10px 10px -8px rgba(0,0,0,0.08);
	box-sizing: border-box;
	overflow: hidden;
	text-overflow: ellipsis;
}
.chatApp__convMessageItem--left .chatApp__convMessageAvatar {
	float: left;
}
.chatApp__convMessageItem--left .chatApp__convMessageValue {
	float: left;
	text-align: left;
	margin: 0 0 0 37px;
}
.chatApp__convMessageItem--left .chatApp__convMessageValue .message{
  background-color: #f1f7ff;
  box-shadow: 0 2px #deebf9;
  border-radius: 7px;
  border-bottom-left-radius: 0;
}
.chatApp__convMessageItem--right .chatApp__convMessageAvatar {
	float: right;
}
.chatApp__convMessageItem--right .chatApp__convMessageValue {
	float: right;
	// text-align: right;
	margin: 0 0 0 0;
}
.chatApp__convMessageItem--right .chatApp__convMessageValue .message{
  background-color: #645d90;
  box-shadow: 0 2px #4a4375;
  border-radius: 7px;
  border-bottom-right-radius: 0;
  color: #fff;
}
.chatApp__convMessageItem--left .chatApp__convMessageValue a {
	color: #20a8d8;
}
.chatApp__convMessageItem--left .chatApp__convMessageValue a:hover,
.chatApp__convMessageItem--left .chatApp__convMessageValue a:focus {
	color: #20a8d8;;
}
.chatApp__convMessageItem--right .chatApp__convMessageValue a {
	color: #fff;
  text-decoration: underline;
}
.chatApp__convMessageItem--right .chatApp__convMessageValue a:hover,
.chatApp__convMessageItem--right .chatApp__convMessageValue a:focus {
	color: #fff;
}
.chatApp__convSendMessage {
	border-bottom-left-radius: 8px;
	border-bottom-right-radius: 8px;
	background-color: #fff;
  position: absolute;
  left:0;
  right: 0;
  bottom: 0;
  padding: 0rem 0.5rem 0.5rem;
}
.chatApp__convInput {
	float: left;
	height: 48px;
	// border: none;
	// padding: 0.5rem 1rem;
	border-radius: 7px;
	margin: 0 5px 0 0;
	width: calc(100% - 53px);
	background-color: #d8d8d8;
	// font-size: 15px;
	transition: 0.15s all ease-in-out;
	box-sizing: border-box;
  position: relative;
}
.chatApp__convInput input{
  overflow: visible;
  height: 100%;
  background-color: #eeeeee;
  // padding: 0.5rem 1rem;
  padding: 0.5rem 65px 0.5rem 15px;
  border: none;
  font-size: 15px;
  width: 100%;
  // padding-left: 37px;
  border-radius: 7px;
}
.chatApp__convInput input[type="text"]:focus{
  outline : none;
}
.chatApp__convInput .shortCodes{
  max-width: 270px;
  display: inline-block;
  width: 270px;
  box-shadow: 0 0 3px rgba(0,0,0,0.3);
  position: absolute;
  background: #fff;
  bottom: 52px;
  right: 0px;
  .site-btn{
    // width: 100%;
    // margin: 0;
    // padding: 7px;
  }
}
.chatApp__convInput .shortCodes ul{
  padding: 0;
  margin: 0;
  max-height: 250px;
  overflow-x: auto;
}
.chatApp__convInput .shortCodes ul li{
  list-style-type: none;
  padding: 7px 7px 0;
  box-shadow: none !important;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0;
  margin: 0;
  font-size: 13px;
  .shortName{
    margin-bottom: 0.3rem;
  }
}
.chatApp__convInput .shortCodes ul li:last-child{border: none!important;}
.chatApp__convInput .upload{
  position: absolute;
  left: 9px;
  top: 11px;
  font-size: 22px;
  color: rgba(38, 38, 38, 0.7);
  cursor: pointer;
}
.chatApp__convInput .emoji,.chatApp__convInput .shortCode-icon{
  position: absolute;
  // right: 9px;
  right: 32px;
  top: 16%;
  font-size: 22px;
  color: rgba(38, 38, 38, 0.7);
  cursor: pointer;
}
.chatApp__convInput .shortCode-icon{
  position: absolute;
  right: 5px;
  top: 29%;
  text-align: center;
  width: 18px;
  font-size: 22px;
  color: rgba(38, 38, 38, 0.7);
  cursor: pointer;
}
.emoji svg{
  fill: rgba(38, 38, 38, 0.7);
}
.chatApp__convInput::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
	color: rgba(38, 38, 38, 0.7);
	opacity: 1;
}
.chatApp__convInput:-ms-input-placeholder { /* Internet Explorer 10-11 */
	color: rgba(38, 38, 38, 0.7);
}
.chatApp__convInput::-ms-input-placeholder { /* Microsoft Edge */
	color: rgba(38, 38, 38, 0.7);
}
.chatApp__convInput:focus, .chatApp__convInput:active {
	box-shadow: inset 0 0 0 2px #7690d6;
}
.chatApp__convInput:focus {
	outline: none;
}
.chatApp__convButton {
	float: right;
	position: relative;
	width: 48px;
	height: 48px;
	cursor: pointer;
	padding: 8px;
	border-radius: 7px;
	color: #fff;
	background-color: #c49b5b;;
	text-align: center;
	box-shadow: 0px 14px 10px -8px rgba(0,0,0,0.2);
	transition: 0.15s all ease-in-out;
	box-sizing: border-box;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.chatApp__convButton i {
	position: relative;
  top: 50%;
  left: -6%;
	transform: translateY(-50%);
	padding: 0 0 0 1px;
	font-size: 22px;
}
.chatApp__convButton--loading {
	cursor: wait;
	// background-color: #4870df;
  background-color: $themeText;
}
.chatApp__convButton--loading:hover {
	// background-color: #4870df;
  background-color: $themeText;
}
.chatApp__convButton--loading::before, .chatApp__convButton--loading::after {
	content: '';
	position: absolute;
	z-index: 1;
	display: block;
	left: 0;
	right: 0;
	top: 50%;
	transform: translateY(-50%);
	width: 8px;
	height: 8px;
	margin: auto;
	border-radius: 40px;
	background-color: #fff;
}
.chatApp__convButton--loading::after {
	animation: loadSendMessage 1.5s;
	animation-fill-mode: forwards;
	animation-iteration-count: infinite;
}
.chatApp__convButton--loading i {
	display: none;
}
.chatApp__convSendMessage--typing .chatApp__convTyping {
	display: block;
}
.chatApp__convTyping {
	position: absolute;
	top: 0;
	font-size: 10px;
	font-weight: bold;
}
.chatApp__convTypingDot {
	position: relative;
	left: 4px;
	display: inline-block;
	width: 2px;
	height: 2px;
	background-color: #606060;
	animation: typingMessage 1.5s;
	animation-fill-mode: forwards;
	animation-iteration-count: infinite;
	animation-timing-function: ease-in-out;
}
.chatApp__convTypingDot::before, .chatApp__convTypingDot::after {
	content: '';
	position: absolute;
	display: inline-block;
	width: 2px;
	height: 2px;
	background-color: #606060;
	animation: typingMessage 1.5s;
	animation-fill-mode: forwards;
	animation-iteration-count: infinite;
	animation-timing-function: ease-in-out;
}
.chatApp__convTypingDot::before {
	left: 4px;
	animation-delay: 0.5s;
}
.chatApp__convTypingDot::after {
	left: 8px;
	animation-delay: 1s;
}
@media screen and (max-width:768px) {
	// .chatApp__room { flex-wrap: wrap; padding: 2rem 1rem; }
	// .chatApp__conv { flex-basis: 100%; min-width: auto; width: 100%; margin: 0; }
	// .chatApp__conv:first-of-type { margin: 0 0 4rem 0; }
}
@keyframes loading {
	0%, 80%, 100% { box-shadow: 0 1em 0 -1.3em; }
	40% { box-shadow: 0 1em 0 0; }
}
@keyframes fadeChatApp {
	0% { opacity: 0; transform: translateY(1rem); }
	100% { opacity: 1; transform: translateY(0px); }
}
@keyframes fadeNewMessage {
	0% { opacity: 0; transform: translateY(1rem); }
	100% { opacity: 1; transform: translateY(0px); }
}
@keyframes loadSendMessage {
	0% { opacity: 1; width: 4px; height: 4px; }
	100% { opacity: 0; width: 100%; height: 100%; }
}
@keyframes typingMessage {
	0% { background-color: #606060; }
	50% { background-color: #fff; }
	60% { background-color: #606060; }
}

.chats .preview-address, .chats .preview-address label{
  font-family: 'avenirltstd-book';
}


.badge-danger {
 background-color: #d73232!important;
}

.slideIn {
  animation: slideIn 0.5s forwards;
  -webkit-animation: slideIn 0.5s forwards;
}

.slideOut {
  animation: slideOut 0.5s forwards;
  -webkit-animation: slideOut 0.5s forwards;
}

@keyframes slideIn {
  100% { left: 0; }
}

@-webkit-keyframes slideIn {
  100% { left: 0; }
}

@keyframes slideOut {
  0% { transform: translateX(0%); }
  100% { transform: translateX(-100%); }
}

@-webkit-keyframes slideOut {
  0% { -webkit-transform: translateX(0%); }
  100% { -webkit-transform: translateX(-100%); }
}
.bot{
  clear: both;
  padding-top: 0 !important;
  .list{
    background-color: #f6f9fd;
    box-shadow: 0px 2px #d6d6d6;
    border-radius: 7px;
    margin-bottom: 9px;
    padding: 9px;
  }
}
.no-conversation{
  position: absolute;
  top:50%;
  left:50%;
  transform: translate(-50%, -50%);
  text-align: center;
  .no-message-text{
    font-family: 'Avenir-Heavy';
  }
}
.clear-left{
  float: left;
}
.history-list {
  margin: 0;
  padding: 0;
  text-align: left;
   display: block;
   padding-bottom: 20px;
  li{
    list-style-type: none;
  }
}
.servicearea-btn{
  background-color: #D2B384 !important;
  color: #fff !important;
  border: none !important;
  position: absolute;
  right: 13px;
  top: 2px;
  padding: 6px 12px !important;
  font-size: 14px !important;
}
.bubbles{
  position: relative;
  display: inline-block;
  background: #e8e8e8;
  padding: 3px 22px 3px 6px;
  border-radius: 10px;
  margin: 0 5px 5px 0;
}
.area-delete{
  position: absolute;
  right: 2px;
  padding: 3px 4px;
  font-size: 13px;
  cursor: pointer;
}
.avatar-sm {
width: 3rem;
height: 3rem;
object-fit: cover;
border-radius: 50%;
}
.avatar-lg {
width: 5rem;
height: 5rem;
object-fit: cover;
border-radius: 50%;
}
